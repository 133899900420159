<template>
  <CommonPages id="detail" :class="['detail', `theme-${domainConfig?.designType || 'default'}`]">
    <div class="book-nav-row">
      <div class="book-nav-item cursor" @click="toggleNav()">
        {{ formatName(part) }}
        <img v-show="showNav" src="@/assets/arrow.png" alt="" class="nav-arrow">
        <img v-show="!showNav" class="nav-arrow rotate" src="@/assets/arrow.png" alt="">
      </div>
    </div>

    <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_1"
      ref="ads-home-insert-1" class="ads-insert-max pc_padding  m-t-40"
      :ads="adsensConfig?.detail_1" :showDebug="showDebug" />

    <div class="book-detail-row pc_padding m_set_width">
      <div v-show="showNav" class="book-list-nav-row">
        <div class="book-list-nav-left">
          <div v-for="(chap, i) in currentPartChapters" :key="i" class="book-list-nav-item cursor"
            :class="{'active': chap === chapter}" @click="chooseChapter(chap)">
            {{ chap }}
          </div>

          <div class="other-book">
            <div v-for="(otherPart, i) in otherParts" :key="i" class="other-book-item cursor"
              @click="choosePart(otherPart)">
              {{ formatName(otherPart) }}
            </div>
          </div>
        </div>
      </div>

      <div class="chapter-container">
        <div class="chapter-catalogue">
          <div class="chapter-catalogue-point" />
          {{ formatName(chapter) }}
        </div>

        <AdComponent
          v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_1"
          ref="ads-detail-insert-1" class="ads-insert-max pc_padding " :ads="adsensConfig?.detail_1"
          :showDebug="showDebug" />
        <AdComponent
          v-if="deviceType == 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_2"
          ref="ads-detail-insert-2" class="ads-insert-max pc_padding m-t-300"
          :ads="adsensConfig?.detail_2" :showDebug="showDebug" />

        <div v-if="chapterContent" class="chapter-row">
          <div v-html="chapterContent" />
        </div>

        <!-- PC端章节导航 -->
        <div v-if="currentPartChapters && deviceType != 'mobile'" class="chapter-nav-row">
          <div class="chapter-nav-item cursor"
            :class="{'active': chapter !== currentPartChapters[0]}" @click="gotoBeginning()">
            <img src="@/assets/chapter-arrow.png" alt="">
            Beginning of Chapter
          </div>
          <div class="chapter-nav-item cursor" :class="{'active': hasNextChapter}"
            @click="nextChapter()">
            Next Chapter
            <img src="@/assets/chapter-arrow-r.png" alt="">
          </div>
        </div>

        <!-- 移动端章节导航 -->
        <div v-if="currentPartChapters && deviceType == 'mobile'" v-show="showNavArrow"
          class="chapter-nav-row mobile">
          <div class="chapter-nav-item cursor mobile" :class="{'active': chapterNumber > 1}"
            @click="prevChapter()">
            <img src="@/assets/chapter-arrow.png" alt="">
          </div>
          <div class="chapter-nav-item cursor mobile" :class="{'active': hasNextChapter}"
            @click="nextChapter()">
            <img src="@/assets/chapter-arrow-r.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.detail_insert?.[0]"
      ref="ads-detail-insert-1" class="ads-insert-max pc_padding padding-horizon-40 m-t-40"
      :ads="adsensConfig?.detail_insert?.[0]" :showDebug="showDebug" /> -->
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/page_common.vue'
import '@/css/home.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      showNav: true,
      activeIndex: null,
      activeTestament: null,
      activeTestamentBooks: null,
      bookInfo: null,
      bookname: null,
      abbrev: null, // 可以根据路由参数或其他方式获取
      books: [],
      chapterContent: null,
      versesContent: null,
      currentChapter: null,

      hasInitBooksFromServer: false,
      /////////列表示例
      movieList: [],
      hasNext: true,
      page: 1,
      observedAds: null,
      part: null,
      chapter: null,
      currentPartChapters: [],
      otherParts: [],
      showNavArrow: false
    };
  },
  computed: {
    title () {
      return `${this.formatName(this.part)} | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return `${this.domainConfig?.['meta']?.des || 'Default des'}`
    },
    oldTestamentBooks () {
      return this.books.filter(book => book.testament === 'Old Testament');
    },
    newTestamentBooks () {
      return this.books.filter(book => book.testament === 'New Testament');
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

    chapterNumber () {
      return parseInt(this.$route.params.chapters) || 1;
    },

    hasNextChapter () {
      return this.currentPartChapters &&
        this.chapterNumber < this.currentPartChapters.length;
    }
  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    const { part, chapters } = this.$route.params;
    console.log(`part: ${part}, chapters: ${chapters}`);

    if (this.deviceType == 'mobile') {
      this.showNav = false
    }

    this.part = this.formatName(part);
    this.chapter = this.formatName(chapters);

    if (this.$store.state.books.length > 0) {
      this.hasInitBooksFromServer = true;
      this.books = this.$store.state.books;
    }
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },

  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)

    // 检查服务端数据是否有效
    const hasValidServerData = this.$root.$options?.context?.hasValidServerData
    console.log('Server data validation:', hasValidServerData)

    // 只有在 store 中没有数据时才调用 initPart
    if (!this.hasInitBooksFromServer || !hasValidServerData) {
      try {
        await this.initPart();
      } catch (err) {
        if (err.message !== 'cancel') {  // 忽略取消的请求错误
          console.error('Failed to fetch data:', err);
        }
      }
    }

    await this.initDetail();

    this.loadAdSenseScript();  // 重新加载广告脚本
    if (typeof window !== 'undefined') {
      this.initAdTracking(); //客户端交互
    }
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    // 滚动监听移除示例
    // window.removeEventListener('scroll', this.onScroll);
    if (this.checkInterval) {
      clearInterval(this.checkInterval); //客户端交互移除
    }
    if (this.visibilityChangeHandler) {
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);//客户端交互移除
    }
  },
  methods: {
    toggleNav () {
      this.showNav = !this.showNav
    },
    formatName (name) {
      return name
        .split('-')
        .join(' ');
    },
    transformName (name) {
      return name
        .split(' ')
        .join('-');
    },
    gotoBeginning () {
      const currentParams = this.$router.currentRoute.params;
      const currentQuery = this.$route.query;
      const channel = currentParams.channel ? `/${currentParams.channel}` : '';
      const queryString = new URLSearchParams(currentQuery).toString();
      const fullQueryString = queryString ? `?${queryString}` : '';

      if (this.currentPartChapters && this.currentPartChapters.length > 0) {
        const firstChapter = this.currentPartChapters[0];
        window.location.href = `${channel}/${this.transformName(this.part)}/${this.transformName(firstChapter)}${fullQueryString}`;
      }
    },

    async prevPage () {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      let index = parseInt(this.activeIndex) - 1
      let abbrev = this.activeTestamentBooks[index].abbrev
      let bookname = this.activeTestamentBooks[index].name
      console.log(bookname, abbrev);
      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(bookname)}/${index}-${abbrev}${fullQueryString}`
    },
    async nextPage () {
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      let index = parseInt(this.activeIndex) + 1
      let abbrev = this.activeTestamentBooks[index].abbrev
      let bookname = this.activeTestamentBooks[index].name
      console.log(bookname, abbrev);
      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(bookname)}/${index}-${abbrev}${fullQueryString}`
    },

    capitalizeFirstLetter (str) {
      if (!str) return str; // 如果字符串为空，直接返回
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    //页面方法 ...
    async chooseBook (abbrev, name, index) {
      console.log(`abbrev:${abbrev}, name:${name}, index:${index}`);
      const currentParams = this.$router.currentRoute.params; // 当前路由的 params
      const currentQuery = this.$route.query; // 当前路由的 query

      const channel = currentParams.channel ? `/${currentParams.channel}` : ''; // 拼接 channel path
      const queryString = new URLSearchParams(currentQuery).toString(); // 转换 query 对象为字符串
      const fullQueryString = queryString ? `?${queryString}` : ''; // 如果有查询参数，拼接为 ?xxx=yyy 格式

      window.location.href = `${channel}/${this.transformName(this.activeTestament)}/${this.transformName(name)}/${index}-${abbrev}${fullQueryString}`

    },

    async initPart () {
      console.log('Fetching parts list');
      const params = {
        type: this.domainConfig?.Type || 'shakespeare',
        pageNum: 1,
        pageSize: 100,
      };

      try {
        const res = await this.$global.server.initPart(params);
        console.log('Parts list:', res);
        if (res?.data) {
          this.books = res.data.partsAndChapters;
          this.author = res.data.author;

          // 找到当前part的chapters
          const currentBook = this.books.find(book =>
            this.transformName(book.part) === this.transformName(this.part)
          );

          if (currentBook) {
            this.currentPartChapters = currentBook.chapters;
            // 获取其他parts
            this.otherParts = this.books
              .filter(book => this.transformName(book.part) !== this.transformName(this.part))
              .map(book => book.part);
          }
        }
      } catch (err) {
        console.error('Error fetching parts:', err);
      }
    },
    async initDetail () {
      const params = {
        type: this.domainConfig?.Type || 'shakespeare',
        part: this.part,
        chapter: this.chapter,
      }

      try {
        const res = await this.$global.server.initDetail(params)
        if (res?.data) {
          this.chapterContent = res.data.content
          setTimeout(() => {
            this.showNavArrow = true
          }, 200);
        }
      } catch (err) {
        console.error('Failed to fetch detail:', err)
      }
    },

    chooseChapter (chapter) {
      const currentParams = this.$router.currentRoute.params;
      const currentQuery = this.$route.query;

      const channel = currentParams.channel ? `/${currentParams.channel}` : '';
      const queryString = new URLSearchParams(currentQuery).toString();
      const fullQueryString = queryString ? `?${queryString}` : '';

      window.location.href = `${channel}/${this.transformName(this.part)}/${this.transformName(chapter)}${fullQueryString}`;
    },

    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
      }
    },
    //广告相关start
    shouldInsertAd (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig?.[pos].indexOf(position) //TODO: 修改adPosConfig.home的字段为你设置的

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldInsertAdDeviece (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig?.[pos]?.indexOf(position) : this.adPosConfig?.[`${pos}PC`]?.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);
      if (!adsElements.length) {
        console.log('No ad elements to observe');
        return; // 如果没有广告元素，直接返回
      }

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && adElement instanceof HTMLElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //跳转
    goto (routername) {
      window.location.href = `/${routername}`
    },

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互
    choosePart (part) {
      const currentParams = this.$router.currentRoute.params;
      const currentQuery = this.$route.query;

      const channel = currentParams.channel ? `/${currentParams.channel}` : '';
      const queryString = new URLSearchParams(currentQuery).toString();
      const fullQueryString = queryString ? `?${queryString}` : '';

      // 跳转到新的part的第一个chapter
      const targetBook = this.books.find(book => this.transformName(book.part) === this.transformName(part));
      if (targetBook && targetBook.chapters.length > 0) {
        window.location.href = `${channel}/${this.transformName(part)}/${this.transformName(targetBook.chapters[0])}${fullQueryString}`;
      }
    },
    getBlogConfig (num) {
      const configs = require('@/data/blogConfigs.json')
      const type = this.domainConfig?.Type || 'shakespeare'
      return configs[type][num] || { title: `Blog ${num}`, desc: 'No description available' }
    },
    nextChapter () {
      const currentParams = this.$router.currentRoute.params;
      const currentQuery = this.$route.query;
      const channel = currentParams.channel ? `/${currentParams.channel}` : '';
      const queryString = new URLSearchParams(currentQuery).toString();
      const fullQueryString = queryString ? `?${queryString}` : '';

      const currentPartIndex = this.books.findIndex(book =>
        this.transformName(book.part) === this.transformName(this.part)
      );

      const currentChapterIndex = this.currentPartChapters.indexOf(this.chapter);

      if (currentChapterIndex < this.currentPartChapters.length - 1) {
        // 当前part还有下一章
        const nextChapter = this.currentPartChapters[currentChapterIndex + 1];
        window.location.href = `${channel}/${this.transformName(this.part)}/${this.transformName(nextChapter)}${fullQueryString}`;
      } else if (currentPartIndex < this.books.length - 1) {
        // 当前part是最后一章，跳转到下一个part的第一章
        const nextPart = this.books[currentPartIndex + 1];
        const firstChapter = nextPart.chapters[0];
        window.location.href = `${channel}/${this.transformName(nextPart.part)}/${this.transformName(firstChapter)}${fullQueryString}`;
      }
    },
    prevChapter () {
      if (this.deviceType === 'mobile') {
        const currentParams = this.$router.currentRoute.params;
        const currentQuery = this.$route.query;
        const channel = currentParams.channel ? `/${currentParams.channel}` : '';
        const queryString = new URLSearchParams(currentQuery).toString();
        const fullQueryString = queryString ? `?${queryString}` : '';

        const currentPartIndex = this.books.findIndex(book =>
          this.transformName(book.part) === this.transformName(this.part)
        );

        const currentChapterIndex = this.currentPartChapters.indexOf(this.chapter);

        if (currentChapterIndex > 0) {
          // 还有上一章
          const prevChapter = this.currentPartChapters[currentChapterIndex - 1];
          window.location.href = `${channel}/${this.transformName(this.part)}/${this.transformName(prevChapter)}${fullQueryString}`;
        } else if (currentPartIndex > 0) {
          // 是第一章，跳转到上一个part的第一章
          const prevPart = this.books[currentPartIndex - 1];
          const firstChapter = prevPart.chapters[0];
          window.location.href = `${channel}/${this.transformName(prevPart.part)}/${this.transformName(firstChapter)}${fullQueryString}`;
        }
      }
    }
  }
};
</script>

